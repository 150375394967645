import isMobile from "is-mobile";

const LEADERBOARD_TABLE_HEADER_CLASSNAME = `${isMobile()?"px-1":"px-6"}`

type LeaderboardProps = {
  leaders: any[]
}

const Leaderboard = (
  {
    leaders
  }: LeaderboardProps
) => {

  return (
    <div className={"columns"}>
      <div className={"column is-12"}>
        <div className={"box p-0"}>
          <div>
            <div
              className={"box mb-0 has-text-centered"}
              style={{
                mixBlendMode: "normal",
                border: "2px solid #F4F6F7",
                boxShadow: "0px 10.8924px 14.5232px rgba(41, 41, 41, 0.25)",
                borderRadius: "25x"
              }}
            >
              <h5
                className={"title my-1 is-size-3-desktop is-size-4-tablet is-size-5-mobile has-text-black"}
              >
                Leaderboard
              </h5>
            </div>
            <div className={"p-4"}>
              <table
                className="table is-fullwidth"
              >
                <thead>
                <tr
                  className={"has-text-centered"}
                >
                  <th className={LEADERBOARD_TABLE_HEADER_CLASSNAME}>
                    {/*<abbr title="Position">Position</abbr>*/}
                    Position
                  </th>
                  <th className={LEADERBOARD_TABLE_HEADER_CLASSNAME}>
                    Name
                  </th>
                  <th className={LEADERBOARD_TABLE_HEADER_CLASSNAME}>
                    {/*<abbr title="Score">Best Score</abbr>*/}
                    Best Score
                  </th>
                </tr>
                </thead>
                <tbody>
                {leaders.map((l, index) => (
                  <tr
                    key={l.id}
                    className={"has-text-centered"}
                  >
                    <th>
                      {index+1}
                    </th>
                    <td>
                      {l.name}
                    </td>
                    <td>
                      {l.user_score}
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Leaderboard