import communityDetails from "../../Common/Models/CommunityDetails";
import {message} from "antd";
import {useState} from "react";
import TimerFinalReward from "../../Common/Models/TimerFinalReward";

type ScoreCardProps = {
  status: 0 | 1 | 2 | 3,
  brandDetails: communityDetails | null,
  score: number,
  reward: TimerFinalReward
}

const ScoreCard = (
  {
    status,
    brandDetails,
    score,
    reward
  }: ScoreCardProps
) => {
  const [copied, setCopied] = useState(false)
  const [s, setShow] = useState(false)

  if(status !== 2 || isNaN(score) || !brandDetails) return null

  const copyCode = () => {
    window.navigator.clipboard.writeText(reward.code)
    setCopied(true)
    message.success("Coupon Code Copied")
  }

  return(
    <div className={"ib-card-parent"}>
      <div
        className={"card ib-community-card"}//Kill Switched
        style={{
          minHeight: "220px"
        }}
        onAnimationEnd={() => {
          setShow(true)
        }}
      >
        <div className={"card-content ib-card-content"}>
          <div className={"has-text-centered"}>
            <h3
              className="title is-size-3-desktop has-text-weight-semibold py-3 is-capitalized"
              style={{
                borderBottom: "1px solid #000000"
              }}
            >
              Congratulations!
            </h3>
          </div>
        </div>
      </div>
      <div
        className={"mt-2"}
        style={{
          position: "static",
          // @ts-ignore
          visibility: s ? "visible" : "hidden",
          transition: "fadeinto 2s"
        }}
      >
        <div
          className={"card teeth-down-card mx-3 "}
          style={{
            position: "relative",
            top: "-120px",
            marginBottom: "-80px"
          }}
        >
          <div
            className={"has-text-centered card-content ibcardpt"}
            style={{
              position:"relative",
              border: "1.76289px solid #F4F6F7",
              mixBlendMode: "normal",
              boxShadow: "0px 10.5773px 14.1031px rgba(41, 41, 41, 0.25)",
              borderRadius: "10.5773px"
            }}
          >
            <div className={"is-size-2 is-size-1-desktop has-text-weight-bold"}>
              {reward.value}% OFF
            </div>
            <div
              className={"has-text-white has-text-weight-semibold has-text-centered is-uppercase py-2 mt-2"}
              style={{
                backgroundColor: "#6C63FF",
                border: "2px solid #6057EB",
                boxShadow: "0px 4.23881px 4.23881px rgba(0, 0, 0, 0.25)",
                borderRadius: "6px"
              }}
            >
              expires in {reward.expiry} days
            </div>
            <div
              className="teeth-down"
            />
            <div className={"mt-4 mb-0"}>
              <span
                className={`${copied ? "copied" : ""} ib-reward-box has-text-weight-bold is-size-4-tablet is-size-5 px-4 py-2`}
                style={{
                  background: "rgba(217, 217, 217, 0.27)",
                  borderRadius: "6px",
                  display: "block"
                }}
              >
                {reward.code}
              </span>
              <button
                className={"button is-dark is-uppercase has-text-weight-bold px-6 is-rounded"}
                style={{
                  top: "50px"
                }}
                onClick={() => copyCode()}
                title={"Click to Copy Code"}
              >
                copy code
                <i
                  hidden
                  className="fa fa-clipboard pl-6"
                  aria-hidden="true"
                />
              </button>
            </div>
          </div>
        </div>
        <div className={"card mx-4"}>
          <div className={"card-content"} id={"is-paddingless"}>
            <div className={"columns is-gapless p-3 is-mobile"}>
              <div className={"column is-narrow"}>
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                />
              </div>
              <div
                className={"column"}
                style={{
                  fontFamily: 'Open Sans, sans-serif',
                  fontWeight: "700",
                  letterSpacing: "20%"
                }}
              >
                This code has been sent to your
                email as well.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ScoreCard