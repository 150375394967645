import {useEffect} from "react";
import {useLocation, Route, Routes} from "react-router-dom";
import BrandPage from "./BrandPage"
import ReactGA from 'react-ga';
import NotFound from "./NotFound";
import BrandApplication from "./BrandApplication";
import QuizHome from "./QuizHome";
import TimerHome from "./TimerHome";
import ScreenshotComponent from "./ScreenshotComponent";

const Home = () => {
    const loc = useLocation()

    useEffect(() => {
      ReactGA.initialize('UA-154475603-13');
      ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])

    useEffect(() => {
    }, [loc.search])

    return(
        <div
            className={"ib-bg isheightmax"}
        >
          <Routes>
            <Route
                path={"/:brand"}
                //@ts-ignore
                element={<ScreenshotComponent />}
            />
            <Route
                path={"*"}
                element={<NotFound />}
            />
          </Routes>
        </div>
    )
}

export default Home