import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Button, Divider, Form, message, Result, Spin} from "antd";
import {BadNotif, SuccessNotif} from "../../Common/Utils/SendNotification";
import isMobile from "is-mobile";
import {capitalize, validateEmail} from "../BrandPage/BrandPage";
import Loader from "../../components/Loader";
import SEO from "../../components/SEO";
import FAQs from "../BrandApplication/FAQs";
import GetTriviaPlayEligible from "../../Common/ApiCall/GetTriviaPlayEligible";
import ScoreCardOld from "./ScoreCard.old";
import PicturesGalleria from "../../components/PicturesGalleria";
import {useStateAndRef} from "../QuizHome/ProgressTimer";
import GetGameDetails from "../../Common/ApiCall/GetGameDetails";
import Sleep from "../../Common/Utils/Sleep";
import TimerGamePlayed from "../../Common/ApiCall/TimerGamePlayed";
import Timer from "./Timer";
import Leaderboard from "./Leaderboard";
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import Rewards from "./Rewards";
import TimerCommunityDetails from "../../Common/Models/TimerCommunityDetails";
import FinalReward from "../../Common/Models/FinalReward";
import ScoreCard from "./ScoreCard";
import ScoreCardDesktop from "./ScoreCard.desktop";
import correctAud from "../../Common/Sounds/Correct-answer.mp3";
import wrongAud from "../../Common/Sounds/Wrong-answer.mp3";
import beats4 from '../../Common/Sounds/4beats.mp3'
import beats1 from '../../Common/Sounds/1beats.mp3'

/*
status
0 - form
1 - thanks
2 - retry screen
3 - game
 */
const timerLights = [
  {key: 1},
  {key: 2},
  {key: 3},
  {key: 4},
  {key: 5}
]
const TIME_FACTO = 4700
const MAX_TIME_TAKEN = -1000
const initialPoints = {}
timerLights.map((a) => (
  // @ts-ignore
  initialPoints[a.key] = false
))

export type statusProps = {
  done: 0 | 1 | 2 | 3,
  timeStatus: "start" | "late" | "early" | "good" | "ultimate"
}

const ScreenshotComponent = () => {
  const [bName, setBname] = useState<string | number | null>(null)
  const [it, setIt] = useState(true)
  const [load, setLoad] = useState(false)
  const p = useParams()
  const [iForm] = Form.useForm()
  const [done, setDone] = useState<statusProps["done"]>(0)
  const [brandDetails, setBrandDetails] = useState<TimerCommunityDetails | null>(null)
  const [score, setScore] = useState(0)
  const [gameId, setGameId] = useState<null | number>(null)
  const [stopped, setStopped] = useState(false)
  const [starting, setStart] = useState(true)
  const [timeStatus, setTimeStatus] = useState<statusProps["timeStatus"]>("start")
  // @ts-ignore
  const [target, setTarget, targetRef] = useStateAndRef(new Date().getTime() + TIME_FACTO)

  const [finalReward, setFinalReward] = useState<FinalReward | null>(null)

  const [listOfImages, setListOfImages] = useState<any[]>([])
  const [activeImage, setActiveImage, activeImageRef] = useStateAndRef(0)
  const [intervalId, setIntervalId, intervalIdRef] = useStateAndRef(null)
  const [valid, setValid] = useState<any>(null)

  const [msLeft, setMsLeft, msLeftRef] = useStateAndRef(0)

  const [points, setPoints] = useState<any>(initialPoints)

  let thousand, hundred, ten, one;

  function debug() {
    setDone(2)
    setTimeStatus('good')
    setValid(4)
    setStart(false)
    setFinalReward({
      code: "3B94E",
      expiry: 5,
      share: false,
      text: "Here is your 10.0 code. Thanks for playing",
      type: "percentage",
      value: 10
    })
  }

  useEffect(() => {
    if (p && p.brand) setBname(p.brand)
    setTimerSpan(9, 9, 9, 9)
    //debug()
    return (
      () => {
        clearInterval(intervalId)
      }
    )
  }, [])

  const playAudio = (correct = false) => {
    //const aud = new Audio(correct ? correctAud : wrongAud)
    const aud = new Audio(beats4)
    aud.volume = 0.5
    aud.play()
  }

  useEffect(() => {
    let cp = {...points}
    const LIGHT_INTERVAL = TIME_FACTO / timerLights.length
    let sound = intervalId && msLeft > 0 && msLeft%LIGHT_INTERVAL < 6
    let it = Math.round(msLeft / LIGHT_INTERVAL)
    if(sound && !cp[it]) {
      cp[it] = true
      setPoints(cp)
      playAudio(true)
    }
  }, [msLeft])


  function start() {
    let interv = setInterval(() => getCountdown(), 2);
    setIntervalId(interv)
  }

  useEffect(() => {
    getBrandDetails()
  }, [bName])

  const stopFirst = () => {
    clearInterval(intervalId)
    setIntervalId(null)
    setDone(3)
  }

  const tryAgain = () => {
    setDone(0)
    setPoints(initialPoints)
    if(isMobile()) {
      scroll.scrollToTop()
    }
    setTarget(new Date().getTime() + TIME_FACTO)
    start()
    setStart(false)
  }

  async function getBrandDetails() {
    if(bName) {
      const res = await GetGameDetails(bName)
      setIt(false)
      if(res.success) {
        let data = res.data
        setBrandDetails({...data})
        let d = document.getElementsByClassName("ib-bg")[0];
        //@ts-ignore
        d.style.backgroundColor = res.data.theme_bg
      } else {
        BadNotif(res)
      }
    }
  }

  async function formSubmit(email: string) {
    let a = iForm.getFieldsValue()
    let e = true
    let b = {
      email: email,
      page_id: bName,
      username: a["ib-community-instagram"],
      eligible: e ? "yes" : "no",
      mobile: isMobile() ? "yes" : "no"
    }
    if(!validateEmail(b.email)) {
      message.warn("Invalid Email.");return;
    }
    setLoad(true)
    const res = await GetTriviaPlayEligible(b)
    setLoad(false)
    if(res.success) {
      setGameId(res.data.game_id)
      setDone(1)
    } else {
      BadNotif(res)
    }
  }

  const played = async (email: string, name: string) => {
    setLoad(true)
    let a = iForm.getFieldsValue()
    let e = true
    if(!email || !validateEmail(email)) {
      setLoad(false)
      message.warn("Invalid Email.");return;
    }
    if(!name) {
      setLoad(false)
      message.warn("Invalid name.");return;
    }
    let b = {
      email: email,
      page_id: bName,
      username: a["ib-community-instagram"],
      eligible: e ? "yes" : "no",
      mobile: isMobile() ? "yes" : "no",
      score: valid,
      game_id: gameId,
      name: name
    }
    const r = await TimerGamePlayed(b)
    if(r.success) {
      SuccessNotif("Score Added.")
      setFinalReward(r.data)
      setDone(2)
      getBrandDetails()
    } else {
      BadNotif(r)
    }
    setLoad(false)
  }

  function onStop(auto = false) {
    let it = intervalIdRef.current
    let ms = msLeftRef.current
    if(ms > 0) {
      setTimeStatus('early')
      setValid(null)
      setIntervalId(null)
      clearInterval(it)
      setStopped(true)
      setDone(2)
    } else {
      let score = Math.abs(msLeft)
      if(auto) {
        setTimeStatus('late')
        setValid(null)
      } else {
        setTimeStatus('good')
        setValid(score)
        if(isMobile()) {
          scroll.scrollTo(400)
        }
      }
      setIntervalId(null)
      clearInterval(it)
      setStopped(true)
      setDone(3)
    }
    if(auto) {
      setMsLeft(0)
      setTimerSpan(0, 0, 0, 0)
    }
    //alert(msLeft - 1000)
    //console.log(Math.abs(msLeft - 1000))
  }

  function getCountdown(){
    // find the amount of "seconds" between now and target
    const current_date = new Date().getTime();
    let ms_left = (targetRef.current - current_date);
    setMsLeft(ms_left)
    if (ms_left < MAX_TIME_TAKEN) {
      onStop(true);ms_left = 0;return;
    }
    thousand = parseInt(String(ms_left / 1000));
    ms_left = ms_left % 1000;
    hundred = parseInt(String(ms_left / 100));
    ms_left = ms_left % 100;
    ten = parseInt(String(ms_left / 10));
    ms_left = ms_left % 10;
    one = parseInt(String(ms_left));
    ms_left = ms_left % 1;
    setTimerSpan(thousand, hundred, ten, one)
  }

  const setTimerSpan = async (t: any, h: any, m: any, s: any) => {
    const countdown = document.getElementById("custom-tiles");
    let txt = "<span>" + t + "</span><span>" + h + "</span><span>" + m + "</span><span>" + s + "</span>";
    //console.log(countdown, countdown?.innerHTML)
    if(countdown) {
      countdown.innerHTML = txt
    } else {
      await Sleep(100)
      setTimerSpan(t, h, m, s)
    }
  }

  const resetTimerSpan = async (t: any, h: any, m: any, s: any) => {
    const countdown = document.getElementById("custom-tiles");
    let txt = "<span>" + t + "</span><span>" + h + "</span><span>" + m + "</span><span>" + s + "</span>";
    if(countdown && !countdown.innerHTML) {
      countdown.innerHTML = txt
    }
  }

  function complete(email: string, name: string) {
    played(email, name)
  }

  if(!thousand) {resetTimerSpan(9, 9, 9, 9)}

  useEffect(() => {
    if(done === 2) {
      setTimerSpan(9, 9, 9, 9)
    }
  })

  const partialEmail = (email: string) => {
    //    return email.replace(/(\w{3})[\w.-]+@([\w.]+.\w)/, "$1***@$2")
    return email.replace(/(\w{3})[\w.-]+@([\w.]+.\w)/, "$1***@*****.com")
  }

  if(it || !brandDetails) {
    if(it) {
      return (
        <section className="hero is-fullheight theme-bg">
          <div className="hero-body has-text-centered">
            <div className="container">
              <Loader />
            </div>
          </div>
        </section>
      )
    } else {
      return (
        <section className="hero is-fullheight theme-bg">
          <div className="hero-body has-text-centered">
            <div className="container">
              <Result
                status="500"
                title="500"
                subTitle="Invalid URL."
              />
            </div>
          </div>
        </section>
      )
    }
  }

  if(load) {
    return(
      <div className={"hero isheightmax theme-bg"}>
        <SEO
          title={capitalize(brandDetails?.brand_name + " | Influencerbit Community")}
          img={brandDetails.brand_logo ?? ""}
        />
        <div className={"hero-body"}>
          <div className={"container"}>
            <div className={"columns is-mobile is-multiline is-centered is-vcentered"}>
              <div className={"column is-10-tablet is-12-mobile"}>
                <div className={"ib-card-parent"}>
                  <div
                    className={"card ib-community-card"}
                    style={{
                      minHeight: "50vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <div
                      className={"card-content ib-card-content has-text-centered"}
                      style={{
                        backgroundColor: brandDetails.bg_color,
                      }}
                    >
                      <Spin />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return(
    <div className={"hero theme-bg"}>
      <SEO
        title={capitalize(brandDetails?.brand_name + " | Influencerbit Community")}
        img={brandDetails.brand_logo ?? ""}
      />
      <div className={"hero-body"}>
        <div className={"container"}>
          <div className={"columns is-mobile is-multiline is-vcentered"}>
            <div
              className={"column is-12"}
              hidden={!!finalReward}
            >
              <Timer
                score={valid}
                starting={starting}
                onStop={onStop}
                tryAgain={tryAgain}
                status={done}
                brandDetails={brandDetails}
                max={0}
                valid={valid}
                stop={stopFirst}
                intervalId={intervalId}
                msLeft={msLeft}
                submit={complete}
                timeStatus={timeStatus}
                total={TIME_FACTO}
                timerLights={timerLights}
              />
            </div>
            {
              done === 3 && (
                <div
                  className={"column is-12"}
                >
                  <ScoreCardOld
                    status={done}
                    brandDetails={brandDetails}
                    score={score}
                    max={0}
                    valid={valid}
                    stop={stopFirst}
                    tryAgain={tryAgain}
                    intervalId={intervalId}
                    submit={complete}
                  />
                </div>
              )
            }
            {
              finalReward && <div
                className={"column is-12"}
              >
                {
                  isMobile() ? (
                    <ScoreCard
                      status={done}
                      brandDetails={brandDetails}
                      score={valid}
                      reward={finalReward}
                    />
                  ) : (
                    <ScoreCardDesktop
                      status={done}
                      brandDetails={brandDetails}
                      score={valid}
                      reward={finalReward}
                    />
                  )
                }
                <div className={"ib-card-parent"}>
                  <div
                    className={"card ib-community-card"}
                    hidden
                  >
                    <div
                      className={"card-content ib-card-content py-6 has-text-centered"}
                      style={{
                        backgroundColor: brandDetails.bg_color,
                      }}
                    >
                      <Divider />
                      <Button
                        hidden={!starting}
                        className={"button is-rounded px-6 is-success is-large has-text-weight-bold has-text-white"}
                        onClick={() => tryAgain()}
                      >
                        Start
                      </Button>
                      <Button
                        hidden={starting}
                        className={"button is-large is-danger is-rounded px-6 has-text-weight-bold"}
                        onClick={() => onStop()}
                      >
                        STOP
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
          <div>
            <Rewards
              rewardItems={brandDetails.rewards}
            />
          </div>
          <Leaderboard leaders={brandDetails.leaders} />
        </div>
        <PicturesGalleria
          pictures={brandDetails.pictures}
          hide={true}
        />
        {brandDetails.faqs.show && (
          <FAQs
            campaign_id={brandDetails.id}
            faqItems={brandDetails.faqs.items}
          />
        )}
      </div>
    </div>
  )
}

export default ScreenshotComponent