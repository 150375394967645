import isMobile from "is-mobile";
import {Button} from "antd";
import {statusProps} from "./ScreenshotComponent";
import CommunityDetails from "../../Common/Models/CommunityDetails";
import React from "react";

const ON_LIGHT = {
  background: "#FF4848",
  border: "3.525px solid #DD2E2E",
  boxShadow: "0px 7.05px 7.05px #FF5056"
}
const OFF_LIGHT = {
  background: "#A0A0A0",
  border: "3.525px solid #595959",
  boxShadow: "0px 7.05px 7.05px #A0A0A0"
}
const DEAD_LIGHT = {
  background: "transparent",
  border: "3.525px solid #000",
  boxShadow: "0px 7.05px 7.05px #A0A0A0"
}

type TimerProps = {
  score: number,
  starting: boolean,
  onStop: Function,
  tryAgain: Function,
  status: statusProps['done'],
  brandDetails: CommunityDetails,
  max: number,
  valid: number | null,
  stop: Function,
  intervalId: number | null,
  submit: Function,
  msLeft: number,
  timeStatus: statusProps["timeStatus"],
  total: number,
  timerLights: any
}

interface scoreIntervalType {
  title: string,
  per: number,
  style: React.CSSProperties
}

const Timer = (
  {
    score,
    starting,
    onStop,
    tryAgain,
    status,
    brandDetails,
    max,
    stop,
    intervalId,
    valid,
    submit,
    msLeft,
    timeStatus,
    total,
    timerLights
  }: TimerProps
) => {

  const LIGHT_INTERVAL = total / timerLights.length

  const correctTry = (status === 3) && (timeStatus === 'good' || timeStatus === 'ultimate')

  const getScoreRange = (): scoreIntervalType => {
    let a: scoreIntervalType = {
      title: "", per: 0, style: {
        borderBottom: ""
      }
    }
    switch (true) {
      case valid === null || isNaN(valid): {
        a = {
          title: "",
          //@ts-ignore
          per: null,
          style: {}
        };break;
      }
      case (valid && valid<11): {
        a = {
          title: "best",
          per: 99,
          style: {
            borderBottom: "10px solid #00AA63",
          }
        };break;
      }
      case (valid && valid<21): {
        a = {
          title: "best",
          per: 97,
          style: {
            borderBottom: "10px solid #00AA63",
          }
        };break;
      }
      case (valid && valid<101): {
        a = {
          title: "best",
          per: 92,
          style: {
            borderBottom: "10px solid #00AA63",
          }
        };break;
      }
      case (valid && valid < 251): {
        a = {
          title: "good",
          per: 82,
          style: {
            borderBottom: "10px solid #ECBA3F",
          }
        };break;
      }
      case (valid && valid < 511): {
        a = {
          title: "good",
          per: 65,
          style: {
            borderBottom: "10px solid #FF4848",
          }
        };break;
      }
      default: {
        a = {
          title: "bad",
          per: 38,
          style: {
            borderBottom: "10px solid #FF4848",
          }
        };break;
      }
    }
    return a
  }

  return(
    <div
      className={"box"}
      style={getScoreRange().style}
    >
      <h5
        className={"title mt-1 mb-55 is-size-5 is-size-6-mobile has-text-black has-text-centered"}
      >
        {brandDetails.brand_title}
      </h5>
      <div className={"columns is-centered"}>
        <div className={"column has-text-centered is-narrow"}>
          <div
            style={{
              minHeight: "100px"
            }}
          >
            <h4
              className={"title is-size-2 mb-2 is-size-4-mobile"}
              style={{
                color: "#FF4848"
              }}
              hidden={!((timeStatus==='early' || timeStatus === 'late') && !intervalId)}
            >
              {
                timeStatus === 'early' ? (
                  <>
                    TOO SLOW!
                  </>
                ) : (
                  <>
                    TOO LATE!
                  </>
                )
              }
            </h4>
            <h4
              className={"title is-underlined is-size-6-mobile is-size-5"}
              hidden={!((timeStatus==='early' || timeStatus === 'late') && !intervalId)}
              style={{
                maxWidth: "572px"
              }}
            >
              Press the button THE MOMENT all 5 red lights are on!
            </h4>
            <h4
              className={"title is-size-3 is-size-5-mobile"}
              hidden={((timeStatus==='early' || timeStatus === 'late') && !intervalId)}
              style={{
                maxWidth: "572px"
              }}
            >
              Hit STOP as soon as all lights are Red and win prizes!
            </h4>
          </div>
          <div
            className={"columns is-justify-content-space-between is-mobile px-4 py-3 is-centered has-background-black"}
            style={{
              boxShadow: "0px 4.15274px 4.15274px rgba(0, 0, 0, 0.25)",
              borderRadius: "5px"
            }}
          >
            {timerLights.map((t: any) => {
              let tot = LIGHT_INTERVAL*timerLights.length
              let passed = (tot - msLeft) >= LIGHT_INTERVAL*t.key
              return(
                <div
                  className={`column p-1 has-background-white light-box ${isMobile()?"mx-1":"mx-2"}`}
                >
                  <div
                    className={`light-circle ${(timeStatus === 'early' && !intervalId && passed) ? "dead" : ""}`}
                    style={passed ? (
                      (timeStatus === 'early' && !intervalId) ? DEAD_LIGHT : (
                        (msLeft===0 && timeStatus ==='start') ? OFF_LIGHT : ON_LIGHT
                      )
                    ) : OFF_LIGHT}
                  >
                    <div className="line1" />
                    <div className="line2" />
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      {
        (intervalId) ? (
          <h5
            className={"title mt-5 mb-5 is-size-4 has-text-centered"}
          >
            Reaction Time: <span className={"has-text-weight-normal"}>{msLeft <0 ? Math.abs(msLeft) : 0} ms</span>
          </h5>
        ) : (
          <h5
            className={"title mt-5 mb-5 is-size-4 has-text-centered"}
            style={score ? {} : {
              color: "#A0A0A0"
            }}
          >
            Reaction Time: <span className={"has-text-weight-normal"}>{score ?? 0}ms</span>
          </h5>
        )
      }
      <div
        className={"has-text-centered"}
        hidden={correctTry}
      >
        <Button
          hidden={!starting}
          className={`button is-rounded px-6 is-success ${isMobile() ? "is-medium" : "is-large"} has-text-weight-bold`}
          onClick={() => tryAgain()}
          style={{
            color: "#fff"
          }}
        >
          Play
        </Button>
        <Button
          hidden={!intervalId}
          className={`button is-rounded px-6 is-danger ${isMobile() ? "is-medium" : "is-large"} has-text-weight-bold`}
          onClick={() => onStop()}
        >
          STOP
        </Button>
        <button
          onClick={() => tryAgain()}
          hidden={!((timeStatus==='early' || timeStatus==='good' || timeStatus === 'ultimate' || timeStatus === 'late') && !intervalId)}
          className={`px-5 is-warning has-text-weight-bold ${isMobile() ? "is-medium" : "is-large"} mt-4 button is-rounded`}
        >
          Naah! Try Again
        </button>
      </div>
      <div
        className={""}
        hidden={!correctTry}
      >
        <p
          className={"has-text-centered"}
          style={{
            fontFamily: 'Inter',
            fontStyle: "italic",
            fontWeight: 100,
            fontSize: "18px",
            lineHeight: "24px"
          }}
        >
          That is better than {getScoreRange().per}% of players!
        </p>
      </div>
    </div>
  )
}

export default Timer