import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGift} from "@fortawesome/free-solid-svg-icons";
import r1 from "../../Common/Images/ribbon/ribbon1.png";
import r2 from "../../Common/Images/ribbon/ribbon2.png";
import r3 from "../../Common/Images/ribbon/ribbon3.png";
import r4 from "../../Common/Images/ribbon/ribbon4.png";
import isMobile from "is-mobile";
import TimerRewardItem from "../../Common/Models/TimerRewardItem";

type RewardItemProps = {
  item: TimerRewardItem
}

const ribbonImages = (rank: number) => {
  if (rank === 0) return r1
  if (rank === 1) return r2
  if (rank === 2) return r3
  return r4
}

const ribbonColors = (rank: number) => {
  if (rank === 0) return "#ECBA3F"
  if (rank === 1) return "#B8BCC5"
  if (rank === 2) return "#C77B30"
  return "#000"
}

const RewardItem = (
  {
    item
  }: RewardItemProps
) => {

  const iconStyles = (rank: number) => {
    let color = ribbonColors(rank)
    return {
      color: color
    }
  }

  const iconClassName = "is-size-1 is-size-4-mobile p-3"

  const clr = ribbonColors(item.rank)

  if(isMobile()) {
    return (
      <div
        className={"column p-2 is-12 has-text-centered"}
        key={item.id}
      >
        <div
          className={"card"}
          style={{
            border: "2px solid #F4F6F7",
            boxShadow: "0px 6px 8px rgba(41, 41, 41, 0.25)",
            borderRadius: "16px"
          }}
        >
          <div
            className={"p-2 columns is-variable is-2 is-vcentered is-mobile"}
          >
            <div className={"column is-narrow"}>
              {
                item.rank <= 3 && (
                  <img
                    src={ribbonImages(item.rank)}
                    alt="Ribbon"
                    style={{
                      maxHeight: "28px"
                    }}
                  />
                )
              }
            </div>
            <div className={"column"}>
              <p
                className={"has-text-weight-bold ib-reward-box p-1"}
                style={{
                  borderRadius: "30px",
                  fontSize: "14px"
                }}
              >
                {item.low}ms - {item.hi}ms
              </p>
            </div>
            <div className={"column"}>
              <p
                className={"has-text-white minifont p-1 mr-0 has-text-weight-bold"}
                style={{
                  backgroundColor: clr,
                  borderRadius: "5px",
                  boxShadow: "0px 3px 3px rgba(0, 0, 0, 0.25)",
                  fontSize: "14px"
                }}
              >
                {item.value}% COUPON
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div
      className={"column is-3-desktop is-4-tablet is-narrow is-half-mobile has-text-centered"}
      key={item.id}
    >
      <div
        className={"card"}
        style={{
          border: "2px solid #F4F6F7",
          boxShadow: "0px 6px 8px rgba(41, 41, 41, 0.25)",
          borderRadius: "16px"
        }}
      >
        <div className={"card-content p-0"}>
          <div
            className={"pt-4 px-4"}
            style={{
              borderBottom: "1px solid black"
            }}
          >
            <span
              className={"has-text-weight-bold ib-reward-box is-size-4 is-size-6-tablet px-3 py-1"}
              style={{
                borderRadius: "30px"
              }}
            >
              {item.low}ms - {item.hi}ms
            </span>
          </div>
          <p
            className={"is-size-4 is-size-5-tablet is-uppercase has-text-white m-2 has-text-weight-bold"}
            style={{
              backgroundColor: clr,
              borderRadius: "5px",
              border: `1.5px solid ${clr}`,
              boxShadow: "0px 3px 3px rgba(0, 0, 0, 0.25)"
            }}
          >
            {item.value}% Coupon
          </p>
          <p className={"p-4"}>
            {item.gift_type === 'product_coupon' ? (
              <FontAwesomeIcon
                icon={faGift}
                style={iconStyles(item.rank)}
                className={iconClassName}
              />
            ) : (
              item.rank <= 3 && (
                <img
                  src={ribbonImages(item.rank)}
                  alt="Ribbon"
                  style={{
                    maxHeight: "96px"
                  }}
                />
              )
            )}
          </p>
        </div>
      </div>
    </div>
  )
}

export default RewardItem