import communityDetails from "../../Common/Models/CommunityDetails";
import {Button, Divider, Form, message} from "antd";
import {useEffect, useState} from "react";
import TimerGamePlayed from "../../Common/ApiCall/TimerGamePlayed";
import isMobile from "is-mobile";
import TimerCommunityDetails from "../../Common/Models/TimerCommunityDetails";

type ScoreCardProps = {
  status: 0 | 1 | 2 | 3,
  brandDetails: TimerCommunityDetails | null,
  score: number,
  max: number,
  tryAgain: Function,
  stop: Function,
  intervalId: number | null,
  valid: null | number,
  submit: Function
}

const QUALIFYING_PERCENTAGE = 0

const ScoreCardOld = (
  {
    status,
    brandDetails,
    score,
    max,
    tryAgain,
    stop,
    intervalId,
    valid,
    submit
  }: ScoreCardProps
) => {
  const [iForm] = Form.useForm()
  const [load,setLoad] = useState(false)

  const formSubmit = () => {
    const email = iForm.getFieldValue("ib-community-email")
    const name = iForm.getFieldValue("ib-community-name")
    submit(email, name)
  }

  if(status === 0 || status === 2 || !valid) return null
  if(status !== 3 || !brandDetails) return null

  let perc = 0
  for(let i of brandDetails.rewards) {
    if(valid >= i.low && valid <= i.hi) {
      perc = i.value
    }
  }

  return (
    <div className={"ib-card-parent"}>
      <div className={"card ib-community-card switched"}>
        <div className={"card-content ib-card-content has-text-centered"}>
        <div className={"columns is-vcentered"}>
          <div
            className={`column is-half ${isMobile()?"px-2":"px-6"}`}
            style={{
              zIndex: 2
            }}
          >
              <h4
                className={"title is-size-4 is-size-5-mobile px-3 pt-4 pb-2"}
                style={{
                  borderBottom: "1.16985px solid rgba(0, 0, 0, 0.3)"
                }}
              >
                Congrats, Redeem a {perc}% Discount Code!
              </h4>
              <div
                className={"is-size-6-tablet"}
              >
                <Form
                  className={"has-text-left"}
                  form={iForm}
                  onFinish={() => formSubmit()}
                >
                  <Form.Item
                    noStyle
                  >
                    <Form.Item
                      name={"ib-community-name"}
                      noStyle
                      rules={[{
                        required: true
                      }]}
                    >
                    </Form.Item>
                    <div
                      className={"ib-form-email-comp"}
                    >
                      <i
                        className="fa fa-ig-ib fa-2x fa-user"
                        aria-hidden="true"
                      />
                      <input
                        type="text"
                        id="ib-instagram-name-input"
                        className={"ib-input ib-instagram-email-input"}
                        placeholder={"Your Name Here"}
                        onChange={e => iForm.setFieldsValue({"ib-community-name": e.target.value})}
                        defaultValue={iForm.getFieldValue("ib-community-name")}
                      />
                    </div>
                  </Form.Item>
                  <Form.Item
                    noStyle
                  >
                    <Form.Item
                      name={"ib-community-email"}
                      rules={[{
                        required: true, type: 'email'
                      }]}
                      noStyle
                    >
                    </Form.Item>
                  </Form.Item>
                  <div
                    className={"ib-form-email-comp"}
                  >
                    <i
                      className="fa fa-ig-ib fa-2x fa-envelope"
                      aria-hidden="true"
                    />
                    <input
                      name={"email"}
                      type={"email"}
                      className={"ib-input ib-instagram-email-input"}
                      placeholder={"Enter Email Here"}
                      defaultValue={iForm.getFieldValue("ib-community-email")}
                      onChange={e => iForm.setFieldsValue({"ib-community-email": e.target.value})}
                      required
                    />
                  </div>
                  <Form.Item
                    noStyle
                    hidden
                  >
                    <Form.Item
                      name={"ib-community-instagram"}
                      noStyle
                    >
                    </Form.Item>
                    <div
                      className={"ib-form-instagram-input-comp"}
                    >
                      <i
                        className="fa fa-ig-ib fa-2x fa-instagram"
                        aria-hidden="true"
                      />
                      <input
                        type="text"
                        id="ib-instagram-username-input"
                        className="ib-input ib-instagram-username-input"
                        placeholder={"Your Instagram Username"}
                        onChange={e => iForm.setFieldsValue({"ib-community-instagram": e.target.value})}
                        defaultValue={iForm.getFieldValue("ib-community-instagram")}
                      />
                    </div>
                  </Form.Item>
                  <Form.Item
                    className={"has-text-centered"}
                    shouldUpdate
                  >
                    {() => {
                      return(
                        <button
                          disabled={load}
                          className={`button is-rounded px-5 is-danger has-text-weight-bold ${isMobile() ? "is-normal" : "is-medium"}`}
                          style={{
                            backgroundColor: "#111A22"
                          }}
                          onClick={() => formSubmit()}
                        >
                          REDEEM {perc}% COUPON
                        </button>
                      )
                    }}
                  </Form.Item>
                </Form>
              </div>
            </div>
          {
            isMobile() ? (
              <Divider
                style={{
                  margin: "0"
                }}
              />
            ) : (
              <div className="vertical-divider">
                <div className="center-element">
                  Or
                </div>
              </div>
            )
          }
          <div
            className={"column is-half"}
          >
            <h5 className={"is-size-5-tablet"}>
              To earn a higher discount you can:
            </h5>
            <div>
              {
                !intervalId ? (
                  <button
                    onClick={() => tryAgain()}
                    className={"px-5 is-warning has-text-weight-bold is-medium mt-4 button is-rounded"}
                  >
                    Naah! Try Again
                  </button>
                ) : (
                  <button
                    onClick={() => stop()}
                    className={"button is-large is-danger"}
                  >
                    STOP
                  </button>
                )
              }
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ScoreCardOld