import TimerRewardItem from "../../Common/Models/TimerRewardItem";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRibbon, faAward, faGift } from '@fortawesome/free-solid-svg-icons'
import r1 from '../../Common/Images/ribbon/ribbon1.png'
import r2 from '../../Common/Images/ribbon/ribbon2.png'
import r3 from '../../Common/Images/ribbon/ribbon3.png'
import r4 from '../../Common/Images/ribbon/ribbon4.png'
import RewardItem from "./RewardItem";

const ribbonColors = (rank: number) => {
  return "#000"
  if(rank === 0) return "#ECBA3F"
  if(rank === 1) return "#B8BCC5"
  if(rank === 2) return "#C77B30"
}

const ribbonImages = (rank: number) => {
  if(rank === 0) return r1
  if(rank === 1) return r2
  if(rank === 2) return r3
  return r4
}

type RewardsProps = {
  rewardItems: TimerRewardItem[]
}

const Rewards = (
  {
    rewardItems
  }: RewardsProps
) => {

  const iconStyles = (rank: number) => {
    let color = ribbonColors(rank)
    return {
      color: color
    }
  }

  if(!rewardItems) return null

  const iconClassName = "is-size-1 is-size-4-mobile p-3"

  return(
    <div
      className={"card my-5"}
      style={{
        borderRadius: "20.5251px",
        border: "3.42085px solid #F4F6F7",
      }}
    >
      <div
        className={"card-content ibcardp"}
      >
        <h4
          className={"title is-size-2-desktop is-size-3-tablet is-size-4-mobile"}
        >
          Prizes ✨
        </h4>
        <div className={"columns is-justify-content-space-around is-variable is-5-desktop is-3-tablet is-multiline"}>
          {rewardItems.map((a) => (
            <RewardItem
              item={a}
              key={a.id}
            />
          ))}
        </div>
      </div>
    </div>
  )

  return(
    <div
      className={"card my-5"}
      style={{
        borderRadius: "20.5251px",
        border: "3.42085px solid #F4F6F7",
      }}
    >
      <div
        className={"card-content"}
      >
        <h4
          className={"title is-size-4 is-size-5-mobile"}
        >
          Prizes ✨
        </h4>
        <div className={"columns is-mobile is-multiline"}>
          {rewardItems.map((a) => (
            <div
              className={"column is-4-tablet is-half-mobile has-text-centered"}
              key={a.id}
            >
              <span
                className={"has-text-weight-bold"}
              >
                {a.low}ms - {a.hi}ms
              </span>
              <br />
              <span>
                {a.value}% Coupon
              </span>
              <br />
              <span>
                {a.gift_type === 'product_coupon' ? (
                  <FontAwesomeIcon
                    icon={faGift}
                    style={iconStyles(a.rank)}
                    className={iconClassName}
                  />
                ) : (
                  a.rank <= 3 && (
                    <img
                      src={ribbonImages(a.rank)}
                      style={{
                        maxHeight: "72px"
                      }}
                    />
                  )
                )}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}


// <FontAwesomeIcon
//   icon={faAward}
//   className={iconClassName}
//   style={iconStyles(a.rank)}
// />

export default Rewards